import striptags from 'striptags';
import { decode } from 'html-entities';

interface StripHtmlOptions {
  html: string; // The HTML string to be stripped of tags
  allowedTags?: string[]; // An optional array of tags that should not be stripped
  tagReplacement?: string; // An optional string to replace stripped tags with
}

function isStripHtmlOptions(input: unknown): input is StripHtmlOptions {
  return typeof input === 'object' && input !== null && 'html' in input;
}
/**
 * Strips HTML tags from a string and decodes it.
 *
 * @param input - The HTML string or an object containing HTML string and optional parameters.
 *   - If a string is provided, it is treated as the HTML content to be stripped.
 *   - If an object is provided, it should have the following properties:
 *     @property html: The HTML string to be stripped of tags.
 *     @property allowedTags: An optional array of tags that should not be stripped.
 *     @property tagReplacement: An optional string to replace stripped tags with.
 * @param decodeHtml - Whether to decode the HTML entities in the string. Defaults to true.
 * @returns The string with HTML tags stripped.
 */
export default function sanitizeHtml(
  input: string | StripHtmlOptions | undefined,
  decodeHtml: boolean = true
): string {
  if (!input || isEmpty(input)) return '';

  let html: string;
  let allowedTags: string[] = [];
  let tagReplacement: string = '';

  if (typeof input === 'string') {
    html = input;
  } else if (isStripHtmlOptions(input)) {
    ({ html, allowedTags = [], tagReplacement = '' } = input);
  } else {
    throw new Error('Invalid input type');
  }

  if (!html) return '';

  if (decodeHtml) {
    return decode(striptags(html, allowedTags, tagReplacement));
  } else {
    return striptags(html, allowedTags, tagReplacement);
  }
}
