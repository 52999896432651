import type { ComposerDateTimeFormatting, ComposerTranslation } from '#i18n';
import dayjs from 'dayjs';
import type { Businesstimes } from '../../../components/Article/Businesstimes/models';
import type {
  Businesstimeslist,
  BusinesstimeslistGroup,
  BusinesstimeslistWeekday,
} from '../../../components/Article/Businesstimeslist/models';
import type { RawAddressbaseDetailsFragment } from '../../../gql/fragments/__generated/RawAddressbaseDetails';
import type { TimeIntervalDefinition } from '../../../gql/schema';
import type { RawAddressbaseListItemFragment } from '../../../gql/fragments/__generated/RawAddressbaseListItem';
import type { Nullable } from '../../../models/CustomUtilityTypes';
import type { i18nCtxObject } from '../../../models/i18nCtxObject';
import { ErrorMessage } from '../../../models/ErrorMessage';

type BusinesstimesDataObject = {
  content: Businesstimes;
  list: Nullable<Businesstimeslist>;
};

export default (
  data: MaybeRef<
    Nullable<RawAddressbaseDetailsFragment | RawAddressbaseListItemFragment>
  >,
  i18nCtx: i18nCtxObject
) => {
  const transformTodayToSingleLineString = computed((): Nullable<string> => {
    const { t, d, locale } = i18nCtx;
    if (!t || !d || !locale) {
      console.error(ErrorMessage.NO_TRANSLATION_CONTEXT);
      return null;
    }

    const openingHoursInformation =
      toValue(data)?.openingHoursInformations?.at(0)?.openingHoursInformation;

    if (isEmpty(openingHoursInformation)) return null;

    const currentOpeningHoursDefinition =
      openingHoursInformation?.openingHourDefinitions?.at(0);
    const timeIntervals = currentOpeningHoursDefinition?.timeIntervals || [];

    return formatTodaysOpeningHours(timeIntervals, d, t, locale.value).join(
      ', '
    );
  });

  const transformToBusinessTimesDataObject = computed(
    (): Nullable<BusinesstimesDataObject> => {
      const { t, d } = i18nCtx;
      if (!t || !d) {
        console.error(ErrorMessage.NO_TRANSLATION_CONTEXT);
        return null;
      }

      const openingHoursInformation =
        toValue(data)?.openingHoursInformations?.at(0)?.openingHoursInformation;

      if (isEmpty(openingHoursInformation)) return null;

      const currentOpeningHoursDefinition =
        openingHoursInformation?.openingHourDefinitions?.at(0);
      const timeIntervals = currentOpeningHoursDefinition?.timeIntervals || [];

      const todaysDate = new Date();

      // Right Section - todays opening hours
      let today = {};
      if (openingHoursInformation?.openToday) {
        // TODO: calculate load
        today = {
          date: d(todaysDate, 'long'),
          times: formatTodaysOpeningHours(timeIntervals, d, t),
          open: openingHoursInformation.openToday,
          // load: t('poi.detail.businesstimes.today.load-high'),
        };
      }

      // Left Section - opening hours list
      const groupedIntervals = groupIntervals(timeIntervals);
      const items = formatGroupedIntervals(groupedIntervals, t, d);

      // Bottom Section - date range information
      const dateRangeInfo =
        !useWhlInstanceConfig().value.poi?.openingHours?.hideDateRangeInfo &&
        currentOpeningHoursDefinition?.dateFrom &&
        currentOpeningHoursDefinition.dateTo
          ? t('poi.detail.businesstimes.info', {
              fromDate: d(
                dayjs(currentOpeningHoursDefinition?.dateFrom).toDate(),
                'long'
              ),
              toDate: d(
                dayjs(currentOpeningHoursDefinition?.dateTo).toDate(),
                'long'
              ),
            })
          : '';

      // Bottom Section - additional opening hours list
      const additionalOpeningHoursDefinitions =
        openingHoursInformation?.openingHourDefinitions?.filter((element) => {
          return element !== currentOpeningHoursDefinition;
        });
      let additionalOpeningHoursDefinitionsList: BusinesstimeslistGroup[] = [];

      if (!isEmpty(additionalOpeningHoursDefinitions)) {
        additionalOpeningHoursDefinitionsList =
          additionalOpeningHoursDefinitions!.map((element) => {
            const timeIntervals = element.timeIntervals || [];
            const groupedIntervals = groupIntervals(timeIntervals);
            const items = formatGroupedIntervals(groupedIntervals, t, d);

            return {
              title: t('poi.detail.businesstimes.list.item.title', {
                fromToDate: joinNonEmptyStrings(
                  [
                    element.dateFrom
                      ? d(dayjs(element.dateFrom).toDate(), 'short')
                      : '',
                    element.dateTo
                      ? d(dayjs(element.dateTo).toDate(), 'short')
                      : '',
                  ],
                  ' - '
                ),
              }),
              items: items,
            };
          });
      }
      return {
        content: {
          title: t('poi.detail.businesstimes.title'),
          today: today,
          list: {
            items: items,
            infos: openingHoursInformation?.openingHourDefinitions?.at(0)
              ?.additionalInformation
              ? [
                  {
                    title: t('poi.detail.businesstimes.list.infos.title'),
                    info: sanitizeHtml(
                      openingHoursInformation?.openingHourDefinitions?.at(0)
                        ?.additionalInformation || ''
                    ),
                  },
                ]
              : [],
          },
          info: dateRangeInfo,
        },
        list: !isEmpty(additionalOpeningHoursDefinitionsList)
          ? {
              title: t('poi.detail.businesstimes.list.title'),
              items: additionalOpeningHoursDefinitionsList,
            }
          : null,
      };
    }
  );

  return {
    transformTodayToSingleLineString,
    transformToBusinessTimesDataObject,
  };
};

function formatTodaysOpeningHours(
  intervals: TimeIntervalDefinition[],
  d: ComposerDateTimeFormatting,
  t: ComposerTranslation,
  locale: string = 'de'
): string[] {
  const todaysDate = new Date();
  const todaysOpeningHours =
    intervals.filter((day) => {
      return day.day?.id === todaysDate.getDay();
    }) || [];

  const todaysTimesInlineStrings = todaysOpeningHours?.map((element) => {
    const from = element.timeFrom
      ? dayjs('1999-00-00' + ' ' + element.timeFrom, 'YYYY-MM-DD HH:mm:ss')
      : null;

    const to = element.timeTo
      ? dayjs('1999-00-00' + ' ' + element.timeTo, 'YYYY-MM-DD HH:mm:ss')
      : null;

    const fromTime = from ? d(from.toDate(), 'time') : '';
    const toTime = to ? d(to.toDate(), 'time') : '';

    let formattedTime = joinNonEmptyStrings([fromTime, toTime], ' - ');
    if (locale === 'de') {
      formattedTime = formattedTime.concat(
        ` ${t('poi.detail.businesstimes.date.clock')}`
      );
    }

    return formattedTime;
  });

  return todaysTimesInlineStrings;
}

function groupIntervals(intervals: TimeIntervalDefinition[]) {
  return intervals.reduce(
    (acc, element) => {
      const dayId = element.day?.id;
      if (!dayId) return acc;

      if (!acc[dayId]) {
        acc[dayId] = [];
      }
      acc[dayId].push(element);
      return acc;
    },
    {} as Record<number, typeof intervals>
  );
}

function formatGroupedIntervals(
  groupedIntervals: Record<number, TimeIntervalDefinition[]>,
  t: ComposerTranslation,
  d: ComposerDateTimeFormatting
): BusinesstimeslistWeekday[] {
  return Object.entries(groupedIntervals).map(([_, intervals]) => {
    const times = intervals.map((element) => {
      const from = element.timeFrom
        ? dayjs('1999-00-00' + ' ' + element.timeFrom, 'YYYY-MM-DD HH:mm:ss')
        : null;

      const to = element.timeTo
        ? dayjs('1999-00-00' + ' ' + element.timeTo, 'YYYY-MM-DD HH:mm:ss')
        : null;

      const fromTime = from ? d(from.toDate(), 'time') : '';
      const toTime = to ? d(to.toDate(), 'time') : '';

      let formattedTime = '';
      if (fromTime && toTime) {
        formattedTime = t('poi.detail.businesstimes.date.fromToTime', {
          fromTime,
          toTime,
        });
      } else if (fromTime) {
        formattedTime = t('poi.detail.businesstimes.date.fromTime', {
          fromTime,
        });
      } else if (toTime) {
        formattedTime = t('poi.detail.businesstimes.date.untilTime', {
          toTime,
        });
      }

      return formattedTime;
    });

    return {
      title: intervals[0].day?.i18nName || '',
      times: times,
    };
  });
}
